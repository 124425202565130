<!--学校资料编辑  -->
<template>
    <div class="editSchoolData">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学校资料编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" id="form-1" :rules="rules" class="" inline label-width="150px">
            <el-form-item label="编号">
                <el-input v-model="form.number" placeholder="请输入编号"></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="简称">
                <el-input v-model="form.abbreviation" placeholder="请输入简称"></el-input>
            </el-form-item>
            <el-form-item label="所在地区" prop="location">
                <el-input v-model="form.location" placeholder="请输入所在地区"></el-input>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择类型" :clearable="true">
                    <el-option v-for="item in form.typeChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                    <el-option v-for="item in form.stateChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="说明">
                <el-input v-model="form.explain" type="textarea" class="textArea-width" placeholder="请输入说明"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    number: "", //编号
                    name: "", //名称
                    abbreviation: "", //简称
                    location: "", //所在地区
                    type: "", //类型
                    typeChoose: [ //类型选择
                        {
                            label: "全部",
                            value: "1"
                        },
                        {
                            label: "小学",
                            value: "2"
                        },
                        {
                            label: "初中",
                            value: "3"
                        },
                        {
                            label: "高中",
                            value: "4"
                        },
                        {
                            label: "完中",
                            value: "5"
                        },
                        {
                            label: "大学",
                            value: "6"
                        },
                        {
                            label: "成人",
                            value: "7"
                        }
                    ],
                    stateChoose: [{
                            label: "可用",
                            value: "1"
                        },
                        {
                            label: "禁用",
                            value: "2"
                        },
                    ], //状态选择
                    condition: "", //状态
                    explain: ''
                },
                rules:{
                     name:[
                          { required: true, message: '请输入名称', trigger: 'blur' },
                      ],
                     location:[
                         { required: true, message: '请输入所在地区', trigger: 'blur' },
                     ],
                     
                },
                bool:false
            }
        },
        created() {
            this.$request({
                url: '/api/school/detail',
                method: 'POST',
                data: {
                    id: this.$route.query.id
                }
            }).then(res => {
                console.log(res)
                var data = res.data.data
                var form = this.form
                form.number = data.number
                form.name = data.name
                form.abbreviation = data.abbreviation
                form.location = data.area
                form.type = data.type + ''
                form.condition = data.status + ''
                form.explain = data.remark
            })
        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        var data = this.form
                        this.$request({
                            url: '/api/school/edit',
                            method: 'POST',
                            data: {
                                number: data.number,
                                abbreviation: data.abbreviation,
                                name: data.name,
                                area: data.location,
                                type: data.type,
                                status: data.condition,
                                remark: data.explain,
                                id: this.$route.query.id
                            }
                        }).then(res => {
                            if (res.code == 1) {
                                this.$message({
                                    message: '修改列表数据成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }else{
                                this.$message({
                                  message: res.msg,
                                  type: 'error'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.goBack()
                this.$refs[form].resetFields();
            }
        }
    }
</script>

<style scoped="scoped">
    .editSchoolData {
        width: 100%;
        height: 100%;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item__label {
        color: #000000;
    }

    .el-input {
        width: 300px;
        height: 37px !important;
    }

    .el-select {
        width: 300px;
    }

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }

    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
